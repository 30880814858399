<template>
  <div class="div7" id="schedule" style="position: relative; z-index: 1">
    <header v-if="backHeader">
      <div class="header-elements">
        <h4 class="back" @click="goToReserveSelect">＜ 戻る</h4>
      </div>
    </header>
    <div v-if="backHeader" class="margin-bottom-48"></div>
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">予約情報</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content background-white height-auto">
      <div class="sd content-t4-s3-sm1">
        <p class="sd text text-value1 text-align-left" style="color: #009aff">
          予約したい日付をクリックして予約画面に進んでください。日付をクリックすることで予約者と各人数を確認することも出来ます。
        </p>
      </div>
      <div class="sd content-t3 height-auto">
        <MyCalendar
          @calendar-item-loaded="updateCalendarItem"
          @go-to-reserve="goToReserve"
        />
      </div>
      <div class="sd content background-white height-auto">
        <ul
          class="calendar_description"
          style="
            color: #ff8700;
            line-height: 1.6;
            list-style-type: none;
            padding-left: 0;
          "
        >
        
        
        <li class="margin-bottom-24">
          平日営業Day：サバゲー・U-18・ナーフ・ちょいサバがご利用できます。
        </li>
        <li class="margin-bottom-24">
          休日営業Day：1Fでサバゲー、2Fでナーフがご利用できます。
        </li>
          <li class="margin-bottom-24">
            ・カリッジュランチDay：1日利用でカリッジュのからあげ弁当が付いてきます♬
          </li>
          <li class="margin-bottom-24">
            ・ガン回しDay：とにかくゲームをガンガン回し！！
          </li>
          <li class="margin-bottom-24">
            ・みんなでU-18＆ナーフDay：10禁サバゲーとナーフの日（U-18料金）
             親子、友達、仕事仲間でナーフ戦を開催♪
          </li>
          <li class="margin-bottom-24">
            ・ビギナーウェルカムDay：初心者も経験者もウェルカム！！
          </li>
          <li class="margin-bottom-24">
            ・ローライトDay：暗闇フィールドへようこそ！
          </li>
          <li class="margin-bottom-24">
            ・サバゲー＆ナーフミックスDay：1日でサバゲーもナーフも両方開催♪
          </li>
          <li class="margin-bottom-24">
            ・ゆったり回しDay：とにかくゲームをゆったり回し！！
          </li>
          <li class="margin-bottom-24">
            ・貸切Day：ゲーム進行料を無料とさせていただきます！
          </li>
          <li class="margin-bottom-24">
            ・お盆開催Day：特別に休日と同じ(08:30～16:30)で開催！
              <br />※料金も休日料金となります。
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MyCalendar from "@/components/MyCalendar.vue";
import { checkLoginInfo, getQueryParam } from "@/modules/common";
export default {
  name: "ReserveCalendar",
  props: ["fromMyPage"],
  components: {
    MyCalendar,
  },
  mounted() {
    if (getQueryParam("backpress")) {
      this.goToReserveSelect();
      return;
    }

    this.initialize();
  },
  created() {
    this.backHeader = this.$route.params.fromMyPage == 1;
  },
  data() {
    return {
      CalendarItem: [],
      Month: "",
      Year: "",
      backHeader: false,
    };
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納し、会員情報を取得して入力欄に格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.login = true;
      }
    },
    goToReserveSelect() {
      window.location.href = "/#/reserve-select";
    },
    goToReserve(selectedDate) {
      this.$router.push({
        name: "temporaryBooking",
        params: {
          selectedDate: selectedDate,
          fromMyPage: this.backHeader ? 1 : 0,
        },
      });
    },
    updateCalendarItem(month, year, calendarItem) {
      if (calendarItem == null || Array.isArray(calendarItem))
        this.CalendarItem = calendarItem;
      else {
        this.CalendarItem = [];
        this.CalendarItem.push(calendarItem);
      }

      this.Month = month + 1;
      this.Year = year;
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.header-elements {
  background-color: white;
}
.header-elements .back {
  color: black;
}
.back:hover {
  border-bottom: 1px solid black;
}

.calendar_description li {
  font-weight: bold;
}
</style>
