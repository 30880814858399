<template>
  <div class="div14" id="div14" name="div14">
    <header v-if="!fromHomePage">
      <div class="header-elements">
        <h4 class="back" @click="goback">＜ 戻る</h4>
      </div>
    </header>
    <div v-if="!fromHomePage" class="header-margin"></div>
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">フィールド利用料金</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content  height-auto">
      <div class="sd content-t5">
        <div class="sd content-t5-s1 width_33">
          <div class="sd content-t3">
            <p class="sd text text-head1">平日料金</p>
            <br />
            <p class="sd text text-value1 margin-bottom-12 text-align-left">
              1日…￥3,000<br />（13:00～21:00）
            </p>
            <br />
            <p class="sd text text-value1 text-align-left">
              半日…￥2,000<br />（13:00～17:00）<br />（18:00～21:00）
            </p>  
            <br />          
            <p class="sd text text-value1 text-align-left">
              ちょいサバ<br />2時間…￥1,000<br />（18:00～） <br />1時間延長につき…￥500
            </p>
            <div class="sd space-20"></div>
            <p class="sd text text-value1 text-align-left">スケジュール</p>
            <br />
            <p class="sd text text-value1 text-align-left">13:00～ 入場開始</p>
            <p class="sd text text-value1 text-align-left">
              13:45～ 初心者説明
            </p>
            <p
              class="text-align-left"
              style="font-size: 15px; font-weight: bold"
            >
              ※初心者の方は13:30までにお越しください
            </p>
            <p class="sd text text-value1 text-align-left">
              14:00～ レギュレーション説明
            </p>
            <p class="sd text text-value1 text-align-left">
              14:30～ ゲーム開始
            </p>
            <p class="sd text text-value1 text-align-left">17:00～ 休憩</p>
            <p class="sd text text-value1 text-align-left">
              18:00～ ゲーム開始
            </p>
            <p class="sd text text-value1 text-align-left">
              21:00～ ゲーム終了
            </p>
            <p class="sd text text-value1 text-align-left">21:30&emsp; 閉店</p>
          </div>
        </div>
        <div class="sd content-t5-s1 width_33">
          <div class="sd content-t3">
            <p class="sd text text-head1">土日祝料金</p>
            <br />
            <p class="sd text text-value1 margin-bottom-12 text-align-left">
              1日…￥4,500<br />（08:30～16:30）
            </p>
            <br />
            <p class="sd text text-value1 text-align-left">
              半日…￥2,500<br />（08:30～12:00）<br />（13:00～16:30）
            </p>
            <div class="sd space-100"></div>
            <p class="sd text text-value1 text-align-left">スケジュール</p>
            <br />
            <p class="sd text text-value1 text-align-left">08:30～ 入場開始</p>
            <p class="sd text text-value1 text-align-left">
              09:30～ 初心者説明
            </p>
            <p
              class="text-align-left"
              style="font-size: 15px; font-weight: bold"
            >
              ※初心者の方は9:20までにお越しください
            </p>
            <p class="sd text text-value1 text-align-left">
              09:45～ レギュレーション説明
            </p>
            <p class="sd text text-value1 text-align-left">
              10:00～ ゲーム開始
            </p>
            <p class="sd text text-value1 text-align-left">12:00～ 昼休憩</p>
            <p class="sd text text-value1 text-align-left">
              13:00～ ゲーム開始
            </p>
            <p class="sd text text-value1 text-align-left">
              16:30～ ゲーム終了
            </p>
            <p class="sd text text-value1 text-align-left">17:00&emsp; 閉店</p>
          </div>
        </div>
        <div class="sd content-t5-s1 width_33">
          <div class="sd content-t3">            
            <p class="sd text text-head1">ナーフ<br />&U-18料金</p><br />
            <p class="sd text text-value1 text-align-left">
              1時間…￥500<br />
              ※1時間利用のみ、<br />大人・子供一律で同じ料金です。<br /><br />
              1日…<br />
              大人：￥3,000<br />
              子供：￥1,500<br /><br />
              半日…<br />
              大人：￥2,000<br />
              子供：￥1,000<br />
              <br /> ※レンタル無料
              <br />(U-18の弾代は実費)
              <br />BB弾：0.12gBB(1000発)<br />定価：￥300<br />
              <br />※U-18に18歳未満のみで参加される場合は「保護者承諾書」をダウンロードし、ご持参ください。
            </p>            
            <br /><a href="pdf/保護者承諾書.pdf" download @click="openPage()">保護者承諾書</a>
          </div>
        </div>
        <div class="sd content-t5-s1 width_33">
          <div class="sd content-t3">
            <p class="sd text text-head1">UABエリア料金</p>
            <br />
            <p class="sd text text-value1 text-align-left">
              2時間…￥1,000<br /><br />※サバゲー、ナーフで入場の場合でも別途利用料金がかかります<br />※1時間延長につき…￥500
            </p>
            <br />
            <div class="line-bold"></div>
            <br />
            <p class="sd text text-head1">試射のみ料金</p>
            <br />
            <p class="sd text text-value1 text-align-left">
              1時間…￥500<br /><br />※サバゲー、ナーフで入場の場合は、無料でご利用できます
            </p>
            <br />
            <div class="line-bold"></div>
            <br />
            <p class="sd text text-head1">キャンセルに<br />ついて</p>
            <br />
            <p class="sd text text-value1 text-align-left">
              通常のご利用の場合は、<br />当日予約時間間近でも<br />
            </p>
            <p class="sd text text-value2 text-align-left">
              キャンセル料は無料!
            </p>
            <p class="sd text text-value1 text-align-left">
              となっております！
              <br />お気軽にご予約下さい！<br />
              <br />※貸切の場合のみ、キャンセル料を頂くことがあります。
              <br />詳しくは貸切料金の欄をご覧ください。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="div23" id="div23" name="div23">
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">貸切料金</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content">
      <div class="sd content-t1">
        <div class="sd content-t2 full-width">
          <div class="sd content-t3">
            <div class="sd content-t4-s3">
              <p class="sd text text-head1">1Fサバゲーフィールド</p>
              <div class="sd space-25"></div>
              <p class="sd text text-head1">平日</p>
              <p class="sd text text-value1 text-align-left">
                （利用時間に関してはご相談ください）
              </p>
              <div class="sd space-25"></div>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">
                  ¥30,000<br />何名様来ていただいても固定の料金になります
                </p>
              </div>
              <div class="sd space-25"></div>
              <p class="sd text text-head1">土日祝</p>
              <p class="sd text text-value1 text-align-left">（8:30～16:30）</p>
              <div class="sd space-25"></div>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">
                  ¥45,000<br />何名様来ていただいても固定の料金になります
                </p>
              </div>
              <div class="sd space-25"></div>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">
                  ※貸切のキャンセルについて
                </p>
              </div>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">
                  &emsp;開催7日前までの貸切のキャンセルは無料です<br /><br />
                  &emsp;貸切のキャンセル料金<br />
                  &emsp;・7日〜&emsp;平日￥10,000<br />
                  &emsp;&emsp;&emsp;&emsp;&emsp; 土日祝￥20,000<br /><br />
                  &emsp;・前日、当日&emsp;全額請求させていただきます<br />
                </p>
              </div>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">
                  ※スタッフによるゲーム進行をご希望の場合は、別途¥10,000とさせていただきます
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="div15" id="div15" name="div15">
    <div class="sd header">
      <div class="sd title">
        <div class="sd t1">
          <h2 class="sd text-standard1 text">レンタル</h2>
        </div>
        <div class="sd line"></div>
      </div>
    </div>
    <div class="sd content">
      <div class="sd content-t1">
        <div class="sd content-t2 width_50">
          <div class="sd content-t3">
            <p class="sd text text-head1">レンタルパック</p>
            <div class="sd space-50"></div>
            <div class="sd content-t4-s3">
              <p class="sd text text-value1 text-align-left">
                ・レンタルパック１
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">
                  （レンタルガン,フェイスマスク,手袋,チームマーカー）
                  <br />全日…￥2,000
                  <br />半日…￥1,000
                </p>
              </div>
              <p class="sd text text-value1 text-align-left">
                ・レンタルパック２
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">
                 （レンタルガン,トレーサー,フェイスマスク,手袋,チームマーカー）
                  <br />￥2,500
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="sd content-t2 width_50">
          <div class="sd content-t3">
            <p class="sd text text-head1">レンタル単体</p>
            <div class="sd space-50"></div>
            <div class="sd content-t4-s3">
              <p class="sd text text-value1 text-align-left">
                ・レンタルガン(ハイキャパE)
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">￥1,500</p>
              </div>
              <p class="sd text text-value1 text-align-left">
                ・レンタルガン(スコーピオンMOD.M)
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">￥2,000</p>
              </div>
              <p class="sd text text-value1 text-align-left">・トレーサー</p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">
                  ￥500<br />
                </p>
              </div>
              <p class="sd text text-value1 text-align-left">
                ・フェイスマスク
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">￥500</p>
              </div>
              <p class="sd text text-value1 text-align-left">
                ・手袋(買い切り)
              </p>
              <div class="sd content-t4-s3-sm1">
                <p class="sd text text-value1 text-align-left">&emsp;</p>
                <p class="sd text text-value1 text-align-left">￥100</p>
              </div>
            </div>
          </div>
        </div>
        <div class="sd content-t2 rental-image">
          <div class="rental-image-container">
            <div class="rental-image-text">スコーピオン<br />MOD.M</div>
            <img
              src="images/スコーピオンMOD_M（ドットが打てないため_を使用しています。）.jpg"
            />
          </div>
          <div class="rental-image-container">
            <div class="rental-image-text"><br />チームマーカー</div>
            <img src="images/チームマーカー.jpg" />
          </div>
          <div class="rental-image-container">
            <div class="rental-image-text">ハイキャパE</div>
            <img src="images/ハイキャパE.jpg" />
          </div>
          <div class="rental-image-container">
            <div class="rental-image-text">フェイスマスク</div>
            <img src="images/フェイスマスク.jpg" />
          </div>
          <div class="rental-image-container">
            <div class="rental-image-text">手袋</div>
            <img src="images/手袋.jpg" />
          </div>
          <div class="rental-image-container">
            <div class="rental-image-text">トレーサー</div>
            <img src="images/トレーサー.jpg" />
          </div>
        </div>
      </div>
      <div class="sd" id="description">
        <p class="text text-standard10 text-align-left">
          ※レンタル品には数に限りがございます。予めご了承下さい。
        </p>
        <p class="text text-standard10 text-align-left">
          ※レンタルの場合のBB弾に関してましては現地にてご購入、もしくは持参でお願い致します。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fromHomePage: Boolean,
  },
  name: "PriceView",
  methods: {
    goback() {
      this.$router.push("/my-page");
    },    
    openPage() {
      window.open('pdf/保護者承諾書.pdf', '_blank')
    },
  },
};
</script>
<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.header-elements {
  background-color: white;
}
.header-elements .back {
  color: black;
}
.back:hover {
  border-bottom: 1px solid black;
}
.header-margin {
  margin-top: 20px;
}
</style>
